import axios from "axios";
export const BASE_URL = "https://prod.marketme-app.com";
//export const BASE_URL = "http://localhost:8000";
export const api = axios.create({
  baseURL: BASE_URL,
});
// api
export const API_LOGIN = "/api/loginAsAdmin";
export const API_LOGOUT = "/api/logout";
export const API_GET_USERS_BY_ROLE = "/api/user/list/";
export const API_GET_SELLERS = "/api/user/list/ROLE_SELLER";
export const API_GET_USER = "/api/user/";
export const API_UPDATE_USER = "/api/user/updateByAdmin/";
export const API_DELETE_USER = "/api/user/delete/";
export const API_GET_ALL_PRODUCTS = "/api/product";
export const API_GET_PRODUCT = "/api/product/findByAdmin/";
export const API_DELETE_PRODUCT = "/api/product/deleteByAdmin/";
export const API_UPDATE_PRODUCT = "/api/product/update_admin/";
export const API_GET_ALL_CATEGORIES = "/api/category/list";
export const API_UPDATE_CATEGORY = "/api/category/update/";
export const API_DELETE_CATEGORY = "/api/category/delete/";
export const API_GET_CATEGORY = "/api/category/";
export const API_ADD_CATEGORY = "/api/category/add";
export const API_GET_ALL_SUBCATEGROIES = "/api/subCategory/list";
export const API_ADD_SUBCATEGORY = "/api/subCategory/add";
export const API_DELETE_SUBCATEGORY = "/api/subCategory/delete/";
export const API_GET_SUBCATEGORY = "/api/subCategory/";
export const API_UPDATE_SUBCATEGORY = "/api/subCategory/update/";
// stats
export const API_GET_STATS_SELLER = "/api/user/dashAdmin/statistics/";
export const API_GET_APP_STATS_REGISTER = "/api/user/dashAdmin/buyers_sellers";
export const API_GET_APP_STATS_SELLS_RESERV = "/api/user/dashAdmin/appStats";
// constants
export const USER_ROLE = "userRole";
export const USER_ROLE_BUYER = "ROLE_BUYER";
export const USER_ROLE_SELLER = "ROLE_SELLER";
