import "./productDatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOpenDeleteProductModal,
  setIsServerErrorDeleteProduct,
} from "../../../reducers/ProductReducer";
import { deleteProduct, getProductist } from "../../../actions/productActions";
import Modal from "../../../components/Modal/Modal";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import { useEffect } from "react";
const ProductDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    productList,
    openDeleteProductModal,
    isLoadingDeleteProduct,
    isLoadingGetProducts,
    isServerErrorDeleteProduct,
    isServerErrorGetProducts,
  } = useSelector((state) => state.product);
  useEffect(() => {
    if (productList.length === 0) {
      dispatch(getProductist());
    }
  }, []);
  const IMAGE_BASE = "https://prod.marketme-app.com/images/";
  const productColumns = [
    {
      field: "label",
      headerName: "Label",
      width: 260,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={IMAGE_BASE + params.row.pictures1}
              alt="avatar"
            />
            {params.row.label}
          </div>
        );
      },
    },
    {
      field: "brandName",
      headerName: "BrandName",
      width: 200,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "sellerFullName",
      headerName: "Seller",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={IMAGE_BASE + params.row.seller.picture}
              alt="avatar"
            />
            {params.row.seller.firstName} {params.row.seller.lastName}
          </div>
        );
      },
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDelete = () => {
    dispatch(deleteProduct(itemToDelete._id));
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`view/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={() => {
                setItemToDelete(params.row);
                dispatch(setOpenDeleteProductModal({ value: true }));
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">List Of Products</div>
      {openDeleteProductModal ? (
        <Modal
          isOpen={openDeleteProductModal}
          onCancel={() => dispatch(setOpenDeleteProductModal({ value: false }))}
          onConfirm={handleDelete}
          itemName={itemToDelete?.label}
          isLoading={isLoadingDeleteProduct}
          isError={isServerErrorDeleteProduct}
          onOk={() => {
            dispatch(setOpenDeleteProductModal({ value: false }));
            dispatch(setIsServerErrorDeleteProduct({ value: false }));
          }}
        />
      ) : isLoadingGetProducts ? (
        <LoadingGettingItems />
      ) : isServerErrorGetProducts ? (
        <div className="errorContainer">
          <h1 className="errorText"> server error 😞 </h1>
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={productList}
          columns={productColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          //checkboxSelection
        />
      )}
    </div>
  );
};

export default ProductDatatable;
