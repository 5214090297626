import React from "react";
import "./spinnerInModal.scss";

const SpinnerInModal = () => {
  return (
    <div className="spinner-in-modal-container">
      <div className="spinner-in-modal"></div>
    </div>
  );
};

export default SpinnerInModal;
