import "./categoryDataTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOpenDeleteCategoryModal,
  setProblemInAddCategory,
  setIsErrorDeleteCategory,
} from "../../../reducers/CategoryReducer";
import Modal from "../../../components/Modal/Modal";
import { Link } from "react-router-dom";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import { useEffect, useState } from "react";
import Spinner from "../../../components/loading/Spinner";
import {
  getCategoryList,
  deleteCategory,
  addCategory,
} from "../../../actions/categoryActions";
import AlertModal from "../../../components/AlertModal/AlertModal";
const CategoryDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onAddCategory, setOnAddCategory] = useState(false);
  const [category, setCategory] = useState(null);
  const {
    categoryListToExecute,
    openDeleteCategoryModal,
    isLoadingDeleteCategory,
    isLoadingGetCategories,
    isLoadingAddCategory,
    isErrorGetCategories,
    isErrorDeleteCategory,
    problemAddCategory,
  } = useSelector((state) => state.category);
  useEffect(() => {
    if (categoryListToExecute.length == 0) {
      dispatch(getCategoryList());
    }
  }, []);
  const IMAGE_BASE = "https://prod.marketme-app.com/images/";

  const categoryColumns = [
    {
      field: "label",
      headerName: "label",
      width: 230,
    },
    {
      field: "description",
      headerName: "description",
      width: 230,
    },

    {
      field: "nbSubs",
      headerName: "subCategories",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">{params.row?.subCategories?.length}</div>
        );
      },
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDelete = () => {
    dispatch(deleteCategory(itemToDelete._id));
  };
  const handleAddNewCategory = () => {
    setOnAddCategory(true);
  };
  const handleSubmitAddNewCategory = (event) => {
    event.preventDefault();
    dispatch(addCategory(category));
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setCategory((prev) => ({ ...prev, [id]: value }));
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`view/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={() => {
                setItemToDelete(params.row);
                dispatch(setOpenDeleteCategoryModal({ value: true }));
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  console.log({
    isErrorGetCategories,
  });
  return (
    <>
      <div className="top">
        {onAddCategory && (
          <div className="formAddCategory">
            <button
              style={{ marginLeft: "90%", cursor: "pointer" }}
              onClick={() => {
                setOnAddCategory(false);
              }}
            >
              X
            </button>
            {isLoadingAddCategory ? (
              <Spinner />
            ) : (
              <form onSubmit={handleSubmitAddNewCategory}>
                <div className="formInput">
                  <label>Label</label>
                  <input
                    id="label"
                    type="text"
                    value={category?.label}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="formInput">
                  <label>Description</label>
                  <textarea
                    id="description"
                    type="text"
                    className="description"
                    value={category?.description}
                    onChange={handleInputChange}
                  />
                </div>
                <button className="cancelButton" disabled={false}>
                  Send
                </button>
              </form>
            )}
          </div>
        )}
      </div>
      <div className="bottom">
        {problemAddCategory === "" ? (
          <div className="datatable">
            <div className="datatableTitle">
              List Of Categories
              {!onAddCategory && !isErrorGetCategories && (
                <Link className="link" onClick={handleAddNewCategory}>
                  Add New Category
                </Link>
              )}
            </div>
            {openDeleteCategoryModal ? (
              <Modal
                isOpen={openDeleteCategoryModal}
                onCancel={() =>
                  dispatch(setOpenDeleteCategoryModal({ value: false }))
                }
                onConfirm={handleDelete}
                itemName={itemToDelete?.label}
                isLoading={isLoadingDeleteCategory}
                isError={isErrorDeleteCategory}
                onOk={() => {
                  dispatch(setOpenDeleteCategoryModal({ value: false }));
                  dispatch(setIsErrorDeleteCategory({ value: false }));
                }}
              />
            ) : isLoadingGetCategories ? (
              <LoadingGettingItems />
            ) : isErrorGetCategories ? (
              <div className="errorContainer">
                <h1 className="errorText"> server error 😞 </h1>
              </div>
            ) : (
              <DataGrid
                className="datagrid"
                rows={categoryListToExecute}
                columns={categoryColumns.concat(actionColumn)}
                pageSize={9}
                rowsPerPageOptions={[9]}
                //checkboxSelection
              />
            )}
          </div>
        ) : (
          <AlertModal
            isOpen={problemAddCategory !== ""}
            onConfirm={() => dispatch(setProblemInAddCategory({ value: "" }))}
            itemName={problemAddCategory}
          />
        )}
      </div>
    </>
  );
};

export default CategoryDatatable;
