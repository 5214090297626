import "./category.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import LoadingGettingItems from "../../components/loadingGetttingItems/LoadingGettingItems";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCategoryById,
  getCategoryList,
  getSubCategoryList,
  updateCategory,
  addSubCategory,
  deleteSubCategory,
} from "../../actions/categoryActions";
import {
  setOpenDeleteSubCategoryModal,
  setIsErrorDeleteSubCategory,
} from "../../reducers/CategoryReducer";
import { setUserInfo } from "../../reducers/AuthReducer";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
const Category = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState(null);

  const subCategoryColumns = [
    {
      field: "label",
      headerName: "label",
      width: 300,
    },
    {
      field: "description",
      headerName: "description",
      width: 330,
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`/categories/view/sub/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={() => {
                setItemToDelete(params.row);
                dispatch(setOpenDeleteSubCategoryModal({ value: true }));
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const {
    categoryToEdit,
    isLoadingUpdateCategory,
    isLoadingAddSubCategory,
    openDeleteSubCategoryModal,
    isLoadingDeleteSubCategory,
    isLoadingGetCategory,
    isErrorGetCategory,
    isErrorUpdateCategory,
    isErrorAddSubCategory,
    isErrorDeleteSubCategory,
  } = useSelector((state) => state.category);

  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  const [category, setCategory] = useState(categoryToEdit);
  const [subCategoryToAdd, setSubCategoryToAdd] = useState(null);
  useEffect(() => {
    if (categoryToEdit === null || categoryToEdit._id !== id) {
      dispatch(getCategoryById(id));
    } else {
      setCategory(categoryToEdit);
      setSubCategories(settingSubCategories(categoryToEdit));
    }
  }, [categoryToEdit]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
        dispatch(getCategoryList());
        dispatch(getSubCategoryList());
      }
    }
  }, [userInfo]);
  const settingSubCategories = (category) => {
    let list = [];
    if (category !== null) {
      const subCategoryInitial = category?.subCategories;
      if (subCategoryInitial.length > 0) {
        list = subCategoryInitial.map((subCategory, index) => ({
          ...subCategory,
          id: index + 1,
        }));
      }
    }
    return list;
  };
  const [subCategories, setSubCategories] = useState(
    settingSubCategories(categoryToEdit)
  );
  const [onEdit, setOnEdit] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setCategory((prev) => ({ ...prev, [id]: value }));
  };
  const handleEditCategory = (event) => {
    event.preventDefault();
    dispatch(updateCategory(category));
  };
  const handleInputAddChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    if (subCategoryToAdd?.category === undefined) {
      setSubCategoryToAdd((prev) => ({ ...prev, category: category._id }));
    }
    setSubCategoryToAdd((prev) => ({ ...prev, [id]: value }));
  };
  const handleAddSubCategory = (event) => {
    event.preventDefault();
    dispatch(addSubCategory(subCategoryToAdd)).then((res) => {
      if (res.payload?.status) {
        dispatch(getSubCategoryList()).then(() => dispatch(getCategoryList()));
      }
    });
  };
  const handleDelete = () => {
    dispatch(deleteSubCategory(itemToDelete._id)).then((res) => {
      if (res.payload?.status) {
        dispatch(getSubCategoryList()).then(() => dispatch(getCategoryList()));
      }
    });
  };
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {isErrorAddSubCategory ||
        isErrorGetCategory ||
        isErrorUpdateCategory ? (
          <div className="errorContainer">
            <h1 className="errorText"> server error 😞 </h1>
          </div>
        ) : (
          <>
            <div className="top">
              <div className="left">
                {!onEdit && (
                  <div
                    className="editButton"
                    onClick={() => {
                      setOnAdd(false);
                      setOnEdit(true);
                    }}
                  >
                    Edit
                  </div>
                )}
                {!onAdd && (
                  <div
                    className="addButton"
                    onClick={() => {
                      setOnEdit(false);
                      setOnAdd(true);
                    }}
                  >
                    Add SubCategory
                  </div>
                )}

                <h1 className="title">Information</h1>
                {isLoadingUpdateCategory || isLoadingGetCategory ? (
                  <Spinner />
                ) : (
                  <div className="item">
                    <div className="details">
                      <h1 className="itemTitle">{categoryToEdit?.label}</h1>
                      <div className="detailItem">
                        <span className="itemKey">Description: </span>
                        <span className="itemValue">
                          {categoryToEdit?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {onEdit && (
                <div className="right">
                  <button
                    style={{ marginLeft: "90%", cursor: "pointer" }}
                    onClick={() => {
                      setOnEdit(false);
                    }}
                  >
                    X
                  </button>
                  {isLoadingUpdateCategory || isLoadingGetCategory ? (
                    <Spinner />
                  ) : (
                    <form onSubmit={handleEditCategory}>
                      <div className="formInput">
                        <label>Label</label>
                        <input
                          id="label"
                          type="text"
                          value={category?.label}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="formInput">
                        <label>Description</label>
                        <textarea
                          id="description"
                          type="text"
                          className="description"
                          value={category?.description}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button
                        className="cancelButton"
                        disabled={isLoadingUpdateCategory}
                      >
                        Send
                      </button>
                    </form>
                  )}
                </div>
              )}
              {onAdd && (
                <div className="right">
                  <button
                    style={{ marginLeft: "90%", cursor: "pointer" }}
                    onClick={() => {
                      setOnAdd(false);
                    }}
                  >
                    X
                  </button>
                  {isLoadingUpdateCategory || isLoadingGetCategory ? (
                    <Spinner />
                  ) : (
                    <form onSubmit={handleAddSubCategory}>
                      <div className="formInput">
                        <label>Sub-Label</label>
                        <input
                          id="label"
                          type="text"
                          value={subCategoryToAdd?.label}
                          onChange={handleInputAddChange}
                        />
                      </div>
                      <div className="formInput">
                        <label>Sub-Description</label>
                        <textarea
                          id="description"
                          type="text"
                          className="description"
                          value={subCategoryToAdd?.description}
                          onChange={handleInputAddChange}
                        />
                      </div>
                      <button
                        className="cancelButton"
                        disabled={isLoadingAddSubCategory}
                      >
                        Add SubCategory
                      </button>
                    </form>
                  )}
                </div>
              )}
            </div>

            <div className="bottom">
              {openDeleteSubCategoryModal ? (
                <Modal
                  isOpen={openDeleteSubCategoryModal}
                  onCancel={() =>
                    dispatch(setOpenDeleteSubCategoryModal({ value: false }))
                  }
                  onConfirm={handleDelete}
                  itemName={itemToDelete?.label}
                  isLoading={isLoadingDeleteSubCategory}
                  isError={isErrorDeleteSubCategory}
                  onOk={() => {
                    dispatch(setOpenDeleteSubCategoryModal({ value: false }));
                    dispatch(setIsErrorDeleteSubCategory({ value: false }));
                  }}
                />
              ) : (
                <div className="datatable">
                  {categoryToEdit !== null && (
                    <div className="datatableTitle">
                      List of subCategories of {categoryToEdit.label}
                    </div>
                  )}
                  {isLoadingAddSubCategory || isLoadingGetCategory ? (
                    <LoadingGettingItems />
                  ) : (
                    <DataGrid
                      className="datagrid"
                      rows={subCategories}
                      columns={subCategoryColumns.concat(actionColumn)}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                      //checkboxSelection
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Category;
