import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Featured from "../../components/featured/Featured";
import ChartGlob from "../../components/chartGlob/ChartGlob";
import ChartGlobSellsReser from "../../components/chartGlobSellsReser/ChartGlobSellsReser";
import Table from "../../components/table/Table";
import Login from "../login/Login";
import { USER_TOKEN } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/loading/Spinner";
import React, { useState, useEffect } from "react";
import {
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
import {
  getStats_buyers_sellers,
  getStatsOfApp,
} from "../../actions/statsActions";
const Home = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const {
    statsBy_sellers_buyers,
    isLoadingGettingStats_sellers_buyers,
    isProblemGettingStats_sellers_buyers,
    isLoadingGettingStats_sells_reser,
    isProblemGettingStats_sells_reser,
    stats_sells_reser,
  } = useSelector((state) => state.stats);
  console.log(statsBy_sellers_buyers);
  const user = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  const [currentYearSelected, setCurrentYearSelected] = useState([2024]);
  const [currentMounthSelected, setCurrentMounthSelected] = useState([]);
  const [currentYearSelected2, setCurrentYearSelected2] = useState([2024]);
  const [currentMounthSelected2, setCurrentMounthSelected2] = useState([]);
  const [monthsShown, setMonthsShown] = useState([
    "all",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo({ value: user }));
        dispatch(getCategoryList());
        dispatch(getSubCategoryList());
        const data = {
          year: null,
          month: null,
        };
        dispatch(getStats_buyers_sellers(data));
        dispatch(getStatsOfApp(data));
      }
    }
    if (stats_sells_reser === null) {
      const data = {
        year: null,
        month: null,
      };
      dispatch(getStatsOfApp(data));
    }
    if (statsBy_sellers_buyers === null) {
      const data = {
        year: null,
        month: null,
      };
      dispatch(getStats_buyers_sellers(data));
    }
  }, [userInfo]);
  return user === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          {isLoadingGettingStats_sellers_buyers ? (
            <Spinner />
          ) : (
            <ChartGlob
              aspect={3 / 1}
              title={`registration buyers and sellers`}
              data={statsBy_sellers_buyers}
              year={currentYearSelected}
              setYear={setCurrentYearSelected}
              month={currentMounthSelected}
              setMounth={setCurrentMounthSelected}
              mounths={monthsShown}
              problem={isProblemGettingStats_sellers_buyers}
            />
          )}
          {/* <Widget type="sellers" />
          <Widget type="buyers" />
          <Widget type="sellers-incomes" />
          <Widget type="reservation" /> */}
        </div>
        <div className="widgets">
          {isLoadingGettingStats_sells_reser ? (
            <Spinner />
          ) : (
            <ChartGlobSellsReser
              aspect={3 / 1}
              title={`sells and reservations in app`}
              data={stats_sells_reser}
              year={currentYearSelected2}
              setYear={setCurrentYearSelected2}
              month={currentMounthSelected2}
              setMounth={setCurrentMounthSelected2}
              mounths={monthsShown}
              problem={isProblemGettingStats_sells_reser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
