import "./sellerDatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOpenDeleteSellerModal,
  setIsServerErrorDeleteSeller,
} from "../../../reducers/AuthReducer";
import { deleteUser } from "../../../actions/authActions";
import Modal from "../../../components/Modal/Modal";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import { USER_ROLE_SELLER } from "../../../constant/endpoints";
import { useEffect } from "react";
import { getSellerList } from "../../../actions/authActions";
import { getCategoryList } from "../../../actions/categoryActions";
const SellerDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    sellerList,
    openDeleteSellerModal,
    isLoadingDeleteSeller,
    isLoadingGetSellers,
    isServerErrorDeleteSeller,
    isServerErrorGetSellers,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (sellerList.length === 0) {
      dispatch(getSellerList());
    }
  }, []);
  const IMAGE_BASE = "https://prod.marketme-app.com/images/";

  const sellerColumns = [
    {
      field: "sellerFullName",
      headerName: "seller",
      width: 210,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={IMAGE_BASE + params.row.picture}
              alt="avatar"
            />
            {params.row.sellerFullName}
          </div>
        );
      },
    },
    {
      field: "companyName",
      headerName: "CompanyName",
      width: 230,
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },
    {
      field: "city",
      headerName: "City",
      width: 140,
    },
    {
      field: "phone",
      headerName: "phone",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.isVerified ? (
              <div className={`cellWithStatus active`}>active</div>
            ) : (
              <div className={`cellWithStatus pending`}>pending</div>
            )}
          </>
        );
      },
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDelete = () => {
    const data = {
      id: itemToDelete._id,
      role: USER_ROLE_SELLER,
    };
    dispatch(deleteUser(data));
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`view/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={async () => {
                setItemToDelete(params.row);
                if (isServerErrorDeleteSeller) {
                  await dispatch(
                    setIsServerErrorDeleteSeller({ value: false })
                  );
                  dispatch(setOpenDeleteSellerModal({ value: true }));
                } else {
                  dispatch(setOpenDeleteSellerModal({ value: true }));
                }
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">List Of Sellers</div>
      {openDeleteSellerModal ? (
        <Modal
          isOpen={openDeleteSellerModal}
          onCancel={() => dispatch(setOpenDeleteSellerModal({ value: false }))}
          onConfirm={handleDelete}
          itemName={itemToDelete?.firstName}
          isLoading={isLoadingDeleteSeller}
          isError={isServerErrorDeleteSeller}
          onOk={() => {
            dispatch(setOpenDeleteSellerModal({ value: false }));
            dispatch(setIsServerErrorDeleteSeller({ value: false }));
          }}
        />
      ) : isLoadingGetSellers ? (
        <LoadingGettingItems />
      ) : isServerErrorGetSellers ? (
        <div className="errorContainer">
          <h1 className="errorText"> server error 😞 </h1>
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={sellerList}
          columns={sellerColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          //checkboxSelection
        />
      )}
    </div>
  );
};

export default SellerDatatable;
