import React from "react";
import "./loadingGettingItems.scss";

const LoadingGettingItems = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingGettingItems;
