import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_LOGIN,
  API_LOGOUT,
  BASE_URL,
  api,
  API_GET_USERS_BY_ROLE,
  USER_ROLE_BUYER,
  USER_ROLE_SELLER,
  API_UPDATE_USER,
  API_DELETE_USER,
  API_GET_SELLERS,
  API_GET_USER,
} from "../constant/endpoints";
import { USER_TOKEN } from "../constant/index";
export const userLogin = createAsyncThunk(API_LOGIN, async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}${API_LOGIN}`, data);
    if (response.status && response.data?.status) {
      localStorage.setItem(USER_TOKEN, response.data.data.accessToken);
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
export const getBuyerList = createAsyncThunk(
  API_GET_USERS_BY_ROLE,
  async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(
        `${API_GET_USERS_BY_ROLE}${USER_ROLE_BUYER}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const getSellerList = createAsyncThunk(API_GET_SELLERS, async () => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_SELLERS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const getUser = createAsyncThunk(API_GET_USER, async (id) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_USER}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const updateUser = createAsyncThunk(API_UPDATE_USER, async (data) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const response = await api.put(`${API_UPDATE_USER}${data?._id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
      transformRequest: (data, headers) => {
        return formData;
      },
    });
    return response.data;
  } catch (error) {
    return false;
  }
});
export const deleteUser = createAsyncThunk(API_DELETE_USER, async (data) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const dataToSent = {
      role: data.role,
    };
    const response = await api.put(
      `${API_DELETE_USER}${data.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      dataToSent
    );
    return response.data;
  } catch (error) {
    return false;
  }
});
