import "./chartSeller.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { getStatsOfSeller } from "../../actions/statsActions";
import { useDispatch, useSelector } from "react-redux";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const years = [2022, 2023, 2024, 2025];
const ChartSeller = ({
  aspect,
  title,
  data,
  year,
  setYear,
  month,
  setMounth,
  mounths,
  id,
}) => {
  const dispatch = useDispatch();
  const handleYearChange = (event) => {
    const {
      target: { value },
    } = event;
    const el = typeof value === "string" ? value.split(",") : value;
    let T = [el];
    const data = {
      year: el,
      month: month.length > 0 && month[0] != "all" ? month[0] : null,
    };
    const obj = {
      dateObject: data,
      id: id,
    };
    dispatch(getStatsOfSeller(obj));
    setYear(T);
  };
  const handleMounthChange = (event) => {
    const {
      target: { value },
    } = event;
    const el = typeof value === "string" ? value.split(",") : value;
    console.log(el);
    let T = [el];
    const data = {
      year: year.length > 0 ? year[0] : null,
      month: el != "all" ? el : null,
    };
    const obj = {
      dateObject: data,
      id: id,
    };
    dispatch(getStatsOfSeller(obj));
    setMounth(T);
  };
  return (
    <div className="chart">
      <div className="title">
        {title}
        <div className="chartDropDown">
          <FormControl sx={{ m: 1, width: 100 }}>
            <InputLabel id="demo-multiple-checkbox-label">Year</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={year}
              onChange={handleYearChange}
              input={<OutlinedInput label="Year" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {years.map((label) => (
                <MenuItem key={label} value={label}>
                  <Checkbox checked={year.indexOf(label) > -1} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="chartDropDown">
          <FormControl sx={{ m: 1, width: 100 }}>
            <InputLabel id="demo-multiple-checkbox-label">Month</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={month}
              onChange={handleMounthChange}
              input={<OutlinedInput label="Month" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {mounths.map((label) => (
                <MenuItem key={label} value={label}>
                  <Checkbox checked={month.indexOf(label) > -1} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="reservation" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="sell" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="reservation"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#reservation)"
          />
          <Area
            type="monotone"
            dataKey="sell"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#sell)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartSeller;
