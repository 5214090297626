import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BASE_URL,
  api,
  API_GET_ALL_CATEGORIES,
  API_DELETE_CATEGORY,
  API_UPDATE_CATEGORY,
  API_GET_ALL_SUBCATEGROIES,
  API_ADD_SUBCATEGORY,
  API_GET_CATEGORY,
  API_DELETE_SUBCATEGORY,
  API_GET_SUBCATEGORY,
  API_UPDATE_SUBCATEGORY,
  API_ADD_CATEGORY,
} from "../constant/endpoints";
import { USER_TOKEN } from "../constant/index";
export const getCategoryList = createAsyncThunk(
  API_GET_ALL_CATEGORIES,
  async () => {
    try {
      console.log("[EXECUTING THE ACTION]");
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_ALL_CATEGORIES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const addCategory = createAsyncThunk(
  API_ADD_CATEGORY,
  async (category) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.post(`${API_ADD_CATEGORY}`, category, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const updateCategory = createAsyncThunk(
  API_UPDATE_CATEGORY,
  async (category) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.put(
        `${API_UPDATE_CATEGORY}${category._id}`,
        category,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const deleteCategory = createAsyncThunk(
  API_DELETE_CATEGORY,
  async (id) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.delete(`${API_DELETE_CATEGORY}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const getSubCategoryList = createAsyncThunk(
  API_GET_ALL_SUBCATEGROIES,
  async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_ALL_SUBCATEGROIES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const getCategoryById = createAsyncThunk(
  API_GET_CATEGORY,
  async (id) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_CATEGORY}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const addSubCategory = createAsyncThunk(
  API_ADD_SUBCATEGORY,
  async (subCategory) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.post(`${API_ADD_SUBCATEGORY}`, subCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const deleteSubCategory = createAsyncThunk(
  API_DELETE_SUBCATEGORY,
  async (id) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.delete(`${API_DELETE_SUBCATEGORY}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);

export const getSubCategory = createAsyncThunk(
  API_GET_SUBCATEGORY,
  async (id) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_SUBCATEGORY}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const updateSubCategory = createAsyncThunk(
  API_UPDATE_SUBCATEGORY,
  async (data) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.put(
        `${API_UPDATE_SUBCATEGORY}${data._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
