import "./buyerDatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOpenDeleteModal,
  setIsServerErrorDeleteBuyer,
} from "../../../reducers/AuthReducer";
import { deleteUser } from "../../../actions/authActions";
import Modal from "../../../components/Modal/Modal";
import LoadingGettingItems from "../../../components/loadingGetttingItems/LoadingGettingItems";
import { USER_ROLE_BUYER } from "../../../constant/endpoints";
import { useEffect } from "react";
import { getBuyerList } from "../../../actions/authActions";
const BuyerDatatable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    buyerList,
    openDeleteModal,
    isLoadingDeleteBuyer,
    isLoadingGetBuyers,
    isServerErrorGetBuyers,
    isServerErrorDeleteBuyer,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (buyerList.length === 0) {
      dispatch(getBuyerList());
    }
  }, []);
  const IMAGE_BASE = "https://prod.marketme-app.com/images/";

  const buyerColumns = [
    {
      field: "buyerFullName",
      headerName: "buyer",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={IMAGE_BASE + params.row.picture}
              alt="avatar"
            />
            {params.row.buyerFullName}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },

    {
      field: "phone",
      headerName: "phone",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            {params.row.isVerified ? (
              <div className={`cellWithStatus active`}>active</div>
            ) : (
              <div className={`cellWithStatus pending`}>pending</div>
            )}
          </>
        );
      },
    },
  ];
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDelete = () => {
    const data = {
      id: itemToDelete._id,
      role: USER_ROLE_BUYER,
    };
    dispatch(deleteUser(data));
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                navigate(`view/${params.row._id}`);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={async () => {
                setItemToDelete(params.row);
                if (isServerErrorDeleteBuyer) {
                  await dispatch(setIsServerErrorDeleteBuyer({ value: false }));
                  dispatch(setOpenDeleteModal({ value: true }));
                } else {
                  dispatch(setOpenDeleteModal({ value: true }));
                }
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">List Of Buyers</div>
      {openDeleteModal ? (
        <Modal
          isOpen={openDeleteModal}
          onCancel={() => dispatch(setOpenDeleteModal({ value: false }))}
          onOk={() => {
            dispatch(setOpenDeleteModal({ value: false }));
            dispatch(setIsServerErrorDeleteBuyer({ value: false }));
          }}
          onConfirm={handleDelete}
          itemName={itemToDelete?.firstName}
          isLoading={isLoadingDeleteBuyer}
          isError={isServerErrorDeleteBuyer}
        />
      ) : isLoadingGetBuyers ? (
        <LoadingGettingItems />
      ) : isServerErrorGetBuyers ? (
        <div className="errorContainer">
          <h1 className="errorText"> server error 😞 </h1>
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={buyerList}
          columns={buyerColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          //checkboxSelection
        />
      )}
    </div>
  );
};

export default BuyerDatatable;
