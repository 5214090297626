import { createSlice } from "@reduxjs/toolkit";
import {
  getProductist,
  deleteProduct,
  updateProduct,
  getProduct,
} from "../actions/productActions";
const initialState = {
  productList: [],
  isLoadingGetProducts: false,
  isLoadingDeleteProduct: false,
  isLoadingUpdateProduct: false,
  productToEdit: null,
  openDeleteProductModal: false,
  problemUpdateProduct: "",
  isServerErrorGetProducts: false,
  isServerErrorDeleteProduct: false,
  isServerErrorUpdateProduct: false,
};

const productReducer = createSlice({
  name: "product",
  initialState,
  reducers: {
    setOpenDeleteProductModal: (state, action) => {
      const { value } = action.payload;
      state.openDeleteProductModal = value;
    },
    setProblemInUpdateProduct: (state, action) => {
      const { value } = action.payload;
      state.problemUpdateProduct = value;
    },
    setIsServerErrorDeleteProduct: (state, action) => {
      const { value } = action.payload;
      state.isServerErrorDeleteProduct = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductist.pending, (state) => {
        state.isLoadingGetProducts = true;
        state.isServerErrorGetProducts = false;
      })
      .addCase(getProductist.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          let list = [];
          list = payload.data;
          for (let index = 0; index < list.length; index++) {
            let element = list[index];
            element.id = index + 1;
            element.sellerFullName = `${element.seller.firstName}${element.seller.lastName}`;
          }
          state.productList = list;
        } else {
          state.isServerErrorGetProducts = true;
        }
        state.isLoadingGetProducts = false;
      })
      .addCase(getProductist.rejected, (state, { payload }) => {
        state.isLoadingGetProducts = false;
      })
      .addCase(getProduct.pending, (state) => {
        state.isLoadingUpdateProduct = true;
        state.isServerErrorUpdateProduct = false;
      })
      .addCase(getProduct.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.productToEdit = payload?.data;
        } else {
          state.isServerErrorUpdateProduct = true;
        }
        state.isLoadingUpdateProduct = false;
      })
      .addCase(getProduct.rejected, (state, { payload }) => {
        state.isLoadingUpdateProduct = false;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoadingDeleteProduct = true;
        state.isServerErrorDeleteProduct = false;
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const res = payload;
          state.productList = state.productList.filter(
            (item) => item._id !== res.id
          );
          state.openDeleteProductModal = false;
        } else {
          state.isServerErrorDeleteProduct = true;
        }
        state.isLoadingDeleteProduct = false;
      })
      .addCase(deleteProduct.rejected, (state, { payload }) => {
        state.isLoadingDeleteProduct = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.isLoadingUpdateProduct = true;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          const updatedProduct = payload.data;
          state.productToEdit = updatedProduct;
          state.productList = state.productList?.map((prod) => {
            if (updatedProduct._id === prod._id) {
              let element = updatedProduct;
              element.id = prod.id;
              return element;
            } else return prod;
          });
        } else {
          if (payload.message) {
            state.problemUpdateProduct = payload.message;
          } else {
            state.isServerErrorUpdateProduct = true;
          }
        }
        state.isLoadingUpdateProduct = false;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.isLoadingUpdateProduct = false;
      });
  },
});

export const {
  setOpenDeleteProductModal,
  setProblemInUpdateProduct,
  setIsServerErrorDeleteProduct,
} = productReducer.actions;
export default productReducer.reducer;
