import "./subCategory.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import LoadingGettingItems from "../../components/loadingGetttingItems/LoadingGettingItems";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  getSubCategory,
  getCategoryList,
  getSubCategoryList,
  updateSubCategory,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useParams } from "react-router-dom";
const Category = () => {
  const { id } = useParams();
  const {
    subCategoryToEdit,
    isLoadingUpdateSubCategory,
    isErrorUpdateSubCategory,
  } = useSelector((state) => state.category);

  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState(subCategoryToEdit);
  useEffect(() => {
    if (subCategoryToEdit === null || subCategoryToEdit._id !== id) {
      dispatch(getSubCategory(id));
    } else {
      setSubCategory(subCategoryToEdit);
    }
  }, [subCategoryToEdit]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
        dispatch(getCategoryList());
        dispatch(getSubCategoryList());
      }
    }
  }, [userInfo]);
  const [onEdit, setOnEdit] = useState(false);
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setSubCategory((prev) => ({ ...prev, [id]: value }));
  };
  const handleEditSubCategory = (event) => {
    event.preventDefault();
    dispatch(updateSubCategory(subCategory)).then((res) => {
      if (res.payload?.status) {
        dispatch(getSubCategoryList()).then(() => dispatch(getCategoryList()));
      }
    });
  };
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {isErrorUpdateSubCategory ? (
          <div className="errorContainer">
            <h1 className="errorText"> server error 😞 </h1>
          </div>
        ) : (
          <div className="top">
            <div className="left">
              {!onEdit && (
                <div
                  className="editButton"
                  onClick={() => {
                    setOnEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
              <h1 className="title">Information</h1>
              {isLoadingUpdateSubCategory ? (
                <Spinner />
              ) : (
                <div className="item">
                  <div className="details">
                    <h1 className="itemTitle">{subCategoryToEdit?.label}</h1>
                    <div className="detailItem">
                      <span className="itemKey">Description: </span>
                      <span className="itemValue">
                        {subCategoryToEdit?.description}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {onEdit && (
              <div className="right">
                <button
                  style={{ marginLeft: "90%", cursor: "pointer" }}
                  onClick={() => {
                    setOnEdit(false);
                  }}
                >
                  X
                </button>
                {isLoadingUpdateSubCategory ? (
                  <Spinner />
                ) : (
                  <form onSubmit={handleEditSubCategory}>
                    <div className="formInput">
                      <label>Label</label>
                      <input
                        id="label"
                        type="text"
                        value={subCategory?.label}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="formInput">
                      <label>Description</label>
                      <textarea
                        id="description"
                        type="text"
                        className="description"
                        value={subCategory?.description}
                        onChange={handleInputChange}
                      />
                    </div>
                    <button
                      className="cancelButton"
                      disabled={isLoadingUpdateSubCategory}
                    >
                      Send
                    </button>
                  </form>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
