import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  api,
  API_GET_STATS_SELLER,
  API_GET_APP_STATS_REGISTER,
  API_GET_APP_STATS_SELLS_RESERV,
} from "../constant/endpoints";
import { USER_TOKEN } from "../constant/index";
export const getStatsOfSeller = createAsyncThunk(
  API_GET_STATS_SELLER,
  async ({ dateObject, id }) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_STATS_SELLER}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const stats = response.data?.data;
        let year =
          dateObject?.year === null
            ? new Date().getFullYear()
            : dateObject?.year;
        let nbrMonthesToShow =
          dateObject?.year === null ||
          dateObject?.year == new Date().getFullYear()
            ? new Date().getMonth()
            : 11;
        let resData = [];
        if (!dateObject?.month) {
          for (let index = 0; index < nbrMonthesToShow + 1; index++) {
            let newInd = index + 1;
            let value = {};
            const reservation = stats.reservation.find((e) => e.year == year);
            if (reservation) {
              const y = reservation.months.find((m) => m.month == newInd);
              if (y) {
                value.reservation = y.count;
              } else {
                value.reservation = 0;
              }
            } else {
              value.reservation = 0;
            }
            const sell = stats.sells.find((e) => e.year == year);
            if (sell) {
              const y = sell.months.find((m) => m.month == newInd);
              if (y) {
                value.sell = y.count;
              } else {
                value.sell = 0;
              }
            } else {
              value.sell = 0;
            }
            value.name = newInd;
            resData.push(value);
          }
        } else {
          const daysInMonth = new Date(
            dateObject.year,
            dateObject.month,
            0
          ).getDate();
          if (daysInMonth > 0) {
            for (let index = 0; index < daysInMonth; index++) {
              let newInd = index + 1;
              let value = {};
              const checkReservationsOfYear = stats.reservation.find(
                (e) => e.year == year
              );
              if (checkReservationsOfYear) {
                const checkReservationsOfMonth =
                  checkReservationsOfYear.months.find(
                    (rm) => rm.month == dateObject.month
                  );
                if (checkReservationsOfMonth) {
                  const reservationsInDays = checkReservationsOfMonth.days;
                  const checkReservationByDay = reservationsInDays.find(
                    (rd) => rd.day == newInd
                  );
                  if (checkReservationByDay) {
                    value.reservation = checkReservationByDay.count;
                  } else {
                    value.reservation = 0;
                  }
                } else {
                  value.reservation = 0;
                }
              } else {
                value.reservation = 0;
              }
              const checkSellsOfYear = stats.sells.find((e) => e.year == year);
              if (checkSellsOfYear) {
                const checkSellsOfMonth = checkSellsOfYear.months.find(
                  (sm) => sm.month == dateObject.month
                );
                if (checkSellsOfMonth) {
                  const sellsInDays = checkSellsOfMonth.days;
                  const checkSellByDay = sellsInDays.find(
                    (sd) => sd.day == newInd
                  );
                  if (checkSellByDay) {
                    value.sell = checkSellByDay.count;
                  } else {
                    value.sell = 0;
                  }
                } else {
                  value.sell = 0;
                }
              } else {
                value.sell = 0;
              }
              value.name = newInd;
              resData.push(value);
            }
          }
        }
        const res = {
          success: true,
          resData: resData,
        };
        return res;
      } else {
        const res = {
          success: false,
          resData: [],
        };
        return res;
      }
    } catch (error) {
      const res = {
        success: false,
        resData: [],
      };
      return res;
    }
  }
);
export const getStats_buyers_sellers = createAsyncThunk(
  API_GET_APP_STATS_REGISTER,
  async (dateObject) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_APP_STATS_REGISTER}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const stats = response.data?.data;
        let year =
          dateObject?.year === null
            ? new Date().getFullYear()
            : dateObject?.year;
        let nbrMonthesToShow =
          dateObject?.year === null ||
          dateObject?.year == new Date().getFullYear()
            ? new Date().getMonth()
            : 11;
        let resData = [];
        if (!dateObject?.month) {
          for (let index = 0; index < nbrMonthesToShow + 1; index++) {
            let newInd = index + 1;
            let value = {};
            const sellers = stats.sellers.find((e) => e.year == year);
            if (sellers) {
              const y = sellers.months.find((m) => m.month == newInd);
              if (y) {
                value.sellers = y.count;
              } else {
                value.sellers = 0;
              }
            } else {
              value.sellers = 0;
            }
            const buyers = stats.buyers.find((e) => e.year == year);
            if (buyers) {
              const y = buyers.months.find((m) => m.month == newInd);
              if (y) {
                value.buyers = y.count;
              } else {
                value.buyers = 0;
              }
            } else {
              value.buyers = 0;
            }
            value.name = newInd;
            resData.push(value);
          }
        } else {
          const daysInMonth = new Date(
            dateObject.year,
            dateObject.month,
            0
          ).getDate();
          if (daysInMonth > 0) {
            for (let index = 0; index < daysInMonth; index++) {
              let newInd = index + 1;
              let value = {};
              const checkSellersOfYear = stats.sellers.find(
                (e) => e.year == year
              );
              if (checkSellersOfYear) {
                const checkSellersOfMonth = checkSellersOfYear.months.find(
                  (rm) => rm.month == dateObject.month
                );
                if (checkSellersOfMonth) {
                  const sellersInDays = checkSellersOfMonth.days;
                  const checkSellersByDay = sellersInDays.find(
                    (rd) => rd.day == newInd
                  );
                  if (checkSellersByDay) {
                    value.sellers = checkSellersByDay.count;
                  } else {
                    value.sellers = 0;
                  }
                } else {
                  value.sellers = 0;
                }
              } else {
                value.sellers = 0;
              }
              const checkBuyersOfYear = stats.buyers.find(
                (e) => e.year == year
              );
              if (checkBuyersOfYear) {
                const checkBuyersOfMonth = checkBuyersOfYear.months.find(
                  (sm) => sm.month == dateObject.month
                );
                if (checkBuyersOfMonth) {
                  const buyersInDays = checkBuyersOfMonth.days;
                  const checkBuyersByDay = buyersInDays.find(
                    (sd) => sd.day == newInd
                  );
                  if (checkBuyersByDay) {
                    value.buyers = checkBuyersByDay.count;
                  } else {
                    value.buyers = 0;
                  }
                } else {
                  value.buyers = 0;
                }
              } else {
                value.buyers = 0;
              }
              value.name = newInd;
              resData.push(value);
            }
          }
        }
        const res = {
          success: true,
          resData: resData,
        };
        return res;
      } else {
        const res = {
          success: false,
          resData: [],
        };
        return res;
      }
    } catch (error) {
      const res = {
        success: false,
        resData: [],
      };
      return res;
    }
  }
);
export const getStatsOfApp = createAsyncThunk(
  API_GET_APP_STATS_SELLS_RESERV,
  async (dateObject) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_APP_STATS_SELLS_RESERV}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const stats = response.data?.data;
        let year =
          dateObject?.year === null
            ? new Date().getFullYear()
            : dateObject?.year;
        let nbrMonthesToShow =
          dateObject?.year === null ||
          dateObject?.year == new Date().getFullYear()
            ? new Date().getMonth()
            : 11;
        let resData = [];
        if (!dateObject?.month) {
          for (let index = 0; index < nbrMonthesToShow + 1; index++) {
            let newInd = index + 1;
            let value = {};
            const reservation = stats.reservation.find((e) => e.year == year);
            if (reservation) {
              const y = reservation.months.find((m) => m.month == newInd);
              if (y) {
                value.reservation = y.count;
              } else {
                value.reservation = 0;
              }
            } else {
              value.reservation = 0;
            }
            const sell = stats.sells.find((e) => e.year == year);
            if (sell) {
              const y = sell.months.find((m) => m.month == newInd);
              if (y) {
                value.sell = y.count;
              } else {
                value.sell = 0;
              }
            } else {
              value.sell = 0;
            }
            value.name = newInd;
            resData.push(value);
          }
        } else {
          const daysInMonth = new Date(
            dateObject.year,
            dateObject.month,
            0
          ).getDate();
          if (daysInMonth > 0) {
            for (let index = 0; index < daysInMonth; index++) {
              let newInd = index + 1;
              let value = {};
              const checkReservationsOfYear = stats.reservation.find(
                (e) => e.year == year
              );
              if (checkReservationsOfYear) {
                const checkReservationsOfMonth =
                  checkReservationsOfYear.months.find(
                    (rm) => rm.month == dateObject.month
                  );
                if (checkReservationsOfMonth) {
                  const reservationsInDays = checkReservationsOfMonth.days;
                  const checkReservationByDay = reservationsInDays.find(
                    (rd) => rd.day == newInd
                  );
                  if (checkReservationByDay) {
                    value.reservation = checkReservationByDay.count;
                  } else {
                    value.reservation = 0;
                  }
                } else {
                  value.reservation = 0;
                }
              } else {
                value.reservation = 0;
              }
              const checkSellsOfYear = stats.sells.find((e) => e.year == year);
              if (checkSellsOfYear) {
                const checkSellsOfMonth = checkSellsOfYear.months.find(
                  (sm) => sm.month == dateObject.month
                );
                if (checkSellsOfMonth) {
                  const sellsInDays = checkSellsOfMonth.days;
                  const checkSellByDay = sellsInDays.find(
                    (sd) => sd.day == newInd
                  );
                  if (checkSellByDay) {
                    value.sell = checkSellByDay.count;
                  } else {
                    value.sell = 0;
                  }
                } else {
                  value.sell = 0;
                }
              } else {
                value.sell = 0;
              }
              value.name = newInd;
              resData.push(value);
            }
          }
        }
        const res = {
          success: true,
          resData: resData,
        };
        return res;
      } else {
        const res = {
          success: false,
          resData: [],
        };
        return res;
      }
    } catch (error) {
      const res = {
        success: false,
        resData: [],
      };
      return res;
    }
  }
);
