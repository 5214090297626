import "./categories.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import CategoryDataTable from "./categoryDataTable/CategoryDataTable";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setUserInfo } from "../../reducers/AuthReducer";
import {
  getSubCategoryList,
  getCategoryList,
} from "../../actions/categoryActions";
const Categories = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo({ value: user }));
        dispatch(getCategoryList());
        dispatch(getSubCategoryList());
      }
    }
  }, [userInfo]);
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <CategoryDataTable />
      </div>
    </div>
  );
};

export default Categories;
