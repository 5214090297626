import React from "react";
import "./alertModal.scss";
const AlertModal = ({ isOpen, onConfirm, itemName }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Something went wrong</h2>
        <p>Error : {itemName}</p>
        <div className="button-container">
          {/* <button className="cancel-button" onClick={onCancel}>
                Cancel
              </button> */}
          <button className="confirm-button" onClick={onConfirm}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
