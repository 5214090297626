export const USER_TOKEN = "user_token";
export const IMAGE_BASE = "https://prod.marketme-app.com/images/";
//export const IMAGE_BASE = 'http://localhost:7000/images/';
export const buyerColumns = [
  {
    field: "buyer",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={IMAGE_BASE + params.row.picture}
            alt="avatar"
          />
          {params.row.firstName} {params.row.lastName}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phone",
    headerName: "phone",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <>
          {params.row.isVerified ? (
            <div className={`cellWithStatus active`}>active</div>
          ) : (
            <div className={`cellWithStatus pending`}>pending</div>
          )}
        </>
      );
    },
  },
];
