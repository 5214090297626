import "./seller.scss";
import { DarkModeContext } from "../../context/darkModeContext";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ChartSeller from "../../components/chartSeller/ChartSeller";
import Spinner from "../../components/loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import React, { useState, useEffect, useContext } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { updateUser, getUser } from "../../actions/authActions";
import {
  getCategoryList,
  getSubCategoryList,
} from "../../actions/categoryActions";
import { setUserInfo } from "../../reducers/AuthReducer";
import { getStatsOfSeller } from "../../actions/statsActions";
import { USER_TOKEN } from "../../constant/index";
import Login from "../../pages/login/Login";
import { useParams } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Seller = () => {
  const [currentYearSelected, setCurrentYearSelected] = useState([2024]);
  const [monthsShown, setMonthsShown] = useState([
    "all",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ]);
  const [currentMounthSelected, setCurrentMounthSelected] = useState([]);
  const { darkMode } = useContext(DarkModeContext);
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem(USER_TOKEN);
  const { sellerToEdit, isLoadingUpdateSeller, categoriesNamesOfSeller } =
    useSelector((state) => state.auth);
  const { categoryList, categoryListNames } = useSelector(
    (state) => state.category
  );
  const { statsBySeller, isLoadingGettingStats } = useSelector(
    (state) => state.stats
  );
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSellerCats(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const dispatch = useDispatch();
  const IMAGE_BASE = "https://prod.marketme-app.com/images/";
  //const IMAGE_BASE = "http://localhost:7000/images/";
  const [file, setFile] = useState("");
  const [user, setUser] = useState(sellerToEdit);
  const [sellerCats, setSellerCats] = useState(categoriesNamesOfSeller);
  const [onEdit, setOnEdit] = useState(false);
  useEffect(() => {
    if (sellerToEdit === null || sellerToEdit._id !== id) {
      dispatch(getUser(id));
      const data = {
        year: null,
        month: null,
      };
      const obj = {
        dateObject: data,
        id: id,
      };
      dispatch(getStatsOfSeller(obj));
    } else {
      setUser(sellerToEdit);
      setSellerCats(categoriesNamesOfSeller);
    }
  }, [sellerToEdit]);
  useEffect(() => {
    if (userInfo === null) {
      const user = localStorage.getItem(USER_TOKEN);
      if (user !== null) {
        dispatch(setUserInfo(user));
        dispatch(getCategoryList());
        dispatch(getSubCategoryList());
      }
    }
  }, [userInfo]);
  const handleInputChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    setUser((prev) => ({ ...prev, [id]: value }));
  };
  const getCategoriesFromLabels = (allCategories, labels) => {
    let result = [];
    for (let index = 0; index < labels.length; index++) {
      const element = labels[index];
      const category = allCategories.find((item) => item.label === element);
      if (category) {
        result.push(category);
      }
    }
    return result;
  };
  const handleEditSeller = async (event) => {
    event.preventDefault();
    let updateCategories = false;
    let categories = [];
    const commonLables = await categoriesNamesOfSeller.filter((label) =>
      sellerCats.includes(label)
    );
    if (
      commonLables.length != categoriesNamesOfSeller.length ||
      sellerCats.length != commonLables.length
    ) {
      const list = await getCategoriesFromLabels(categoryList, sellerCats);
      categories = list.map((cat) => cat._id);
      updateCategories = true;
    }
    const data = {
      ...user,
      updateCategories: updateCategories,
      categories: categories,
    };
    dispatch(updateUser(data));
  };
  return token === null && userInfo === null ? (
    <Login />
  ) : (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton" onClick={() => setOnEdit(true)}>
              Edit
            </div>
            <div className="csvInput">
              <label htmlFor="file">
                <DriveFolderUploadOutlinedIcon className="icon" />
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => {}}
                style={{ display: "none" }}
              />
            </div>
            <h1 className="title">Information</h1>
            {isLoadingUpdateSeller ? (
              <Spinner />
            ) : (
              sellerToEdit && (
                <div className="item">
                  <img
                    src={IMAGE_BASE + sellerToEdit.picture}
                    alt=""
                    className="itemImg"
                  />
                  <div className="details">
                    <h1 className="itemTitle">
                      {sellerToEdit.firstName} {sellerToEdit.lastName}
                    </h1>
                    <div className="detailItem">
                      <span className="itemKey">Email: </span>
                      <span className="itemValue">{sellerToEdit.email}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Phone:</span>
                      <span className="itemValue">{sellerToEdit.phone}</span>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="right">
            {isLoadingGettingStats ? (
              <Spinner />
            ) : (
              <ChartSeller
                aspect={3 / 1}
                title={`stats of ${user?.firstName} ${user?.lastName}`}
                data={statsBySeller}
                year={currentYearSelected}
                setYear={setCurrentYearSelected}
                month={currentMounthSelected}
                setMounth={setCurrentMounthSelected}
                mounths={monthsShown}
                id={id}
              />
            )}
          </div>
        </div>
        {onEdit && user && (
          <div className="bottom">
            <div className="left">
              <img
                src={
                  file ? URL.createObjectURL(file) : IMAGE_BASE + user.picture
                }
                alt=""
              />
            </div>
            <div className="right">
              {isLoadingUpdateSeller ? (
                <Spinner />
              ) : (
                <form onSubmit={handleEditSeller}>
                  <div className="formInput">
                    <label htmlFor="file">
                      Image: <DriveFolderUploadOutlinedIcon className="icon" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          picture: e.target.files[0],
                        }));
                        setFile(e.target.files[0]);
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="formInput">
                    <label>First Name</label>
                    <input
                      id="firstName"
                      type="text"
                      value={user.firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput">
                    <label>Last Name</label>
                    <input
                      id="lastName"
                      type="text"
                      value={user.lastName}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formInput">
                    <label>Company Name</label>
                    <input
                      id="companyName"
                      type="text"
                      value={user.companyName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput">
                    <label>City</label>
                    <input
                      id="city"
                      type="text"
                      value={user.city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput">
                    <label>Email</label>
                    <input
                      id="email"
                      type="mail"
                      value={user.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formInput">
                    <label>Phone</label>
                    <input
                      id="phone"
                      type="text"
                      value={user.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <FormControl
                      sx={{
                        m: 1,
                        width: 300,
                        border: darkMode && "1px solid white",
                        color: darkMode && "white",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        sx={{ color: darkMode && "white" }}
                      >
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={sellerCats}
                        onChange={handleCategoryChange}
                        input={<OutlinedInput label="Category" />}
                        //renderValue={(selected) => selected.join(", ")}
                        renderValue={(selected) => (
                          <div style={{ color: darkMode && "white" }}>
                            {selected.join(", ")}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {categoryListNames.map((label) => (
                          <MenuItem key={label} value={label}>
                            <Checkbox
                              checked={sellerCats.indexOf(label) > -1}
                            />
                            <ListItemText primary={label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <button className="ButtonSubmit">Send</button>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Seller;
