import React from "react";
import "./modal.scss";
import { useSelector } from "react-redux";
import SpinnerInModal from "../../components/loadingInModal/SpinnerInModal";
const Modal = ({
  isOpen,
  onCancel,
  onOk,
  onConfirm,
  itemName,
  isLoading,
  isError,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        {isLoading ? (
          <SpinnerInModal />
        ) : (
          <>
            {isError ? (
              <p>something went wrong 😞</p>
            ) : (
              <>
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete {itemName}</p>
              </>
            )}

            <div className="button-container">
              {!isError && (
                <>
                  <button className="cancel-button" onClick={onCancel}>
                    Cancel
                  </button>
                  <button className="confirm-button" onClick={onConfirm}>
                    Confirm
                  </button>
                </>
              )}
              {isError && (
                <button className="cancel-button" onClick={onOk}>
                  Ok
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
