import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  api,
  API_GET_ALL_PRODUCTS,
  API_GET_PRODUCT,
  API_DELETE_PRODUCT,
  API_UPDATE_PRODUCT,
} from "../constant/endpoints";
import { USER_TOKEN } from "../constant/index";
export const getProductist = createAsyncThunk(
  API_GET_ALL_PRODUCTS,
  async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.get(`${API_GET_ALL_PRODUCTS}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const getProduct = createAsyncThunk(API_GET_PRODUCT, async (id) => {
  try {
    const token = localStorage.getItem(USER_TOKEN);
    const response = await api.get(`${API_GET_PRODUCT}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return false;
  }
});
export const deleteProduct = createAsyncThunk(
  API_DELETE_PRODUCT,
  async (id) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.delete(`${API_DELETE_PRODUCT}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
export const updateProduct = createAsyncThunk(
  API_UPDATE_PRODUCT,
  async (product) => {
    try {
      const token = localStorage.getItem(USER_TOKEN);
      const response = await api.put(
        `${API_UPDATE_PRODUCT}${product._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        product
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  }
);
